import React from "react";
import MainView from "./view/MainView";


function MainScreen() {
  return (
    <MainView></MainView>
    // <div>Основная страница</div>
  )
}

export default MainScreen
