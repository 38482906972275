import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import PrimaryButton from "../primaryButton/PrimaryButton";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import OutlineButton from "../outlineButton/OutlineButton";

// Виджет Яндекс.Карты с основным подразделением
const yandexMapWidget = `<div style="position:relative;overflow:hidden;"><a href="https://yandex.ru/maps/org/stomatologicheskiy_kliniko_diagnosticheskiy_tsentr_volgogradskogo_gosudarstvennogo_meditsinskogo_universiteta/1728126763/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Стоматологический клинико-диагностический центр Волгоградского государственного медицинского университета</a><a href="https://yandex.ru/maps/38/volgograd/category/dental_polyclinic/184106130/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:14px;">Стоматологическая поликлиника в Волгограде</a><iframe src="https://yandex.ru/map-widget/v1/?indoorLevel=1&ll=44.546782%2C48.752149&mode=search&oid=1728126763&ol=biz&sctx=ZAAAAAgBEAAaKAoSCU8F3PP8RUZAEQxcHmtGYEhAEhIJFEIHXcKhhz8R0ZLH0%2FIDdz8iBgABAgMEBSgKOABAwM0GSAFiHnJlbGV2X3NlcnZpY2VfYXJlYV9wZXJjZW50PTEwMGoCcnWdAc3MTD2gAQCoAQC9Aep4LauCAmnQodGC0L7QvNCw0YLQvtC70L7Qs9C40YfQtdGB0LrQuNC5INC60LvQuNC90LjQutC%2BLdC00LjQsNCz0L3QvtGB0YLQuNGH0LXRgdC60LjQuSDRhtC10L3RgtGALCDQntCh0J8g4oSWIDGKAgCSAgCaAgxkZXNrdG9wLW1hcHM%3D&sll=44.546782%2C48.752149&sspn=0.011539%2C0.005619&text=%D0%A1%D1%82%D0%BE%D0%BC%D0%B0%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9%20%D0%BA%D0%BB%D0%B8%D0%BD%D0%B8%D0%BA%D0%BE-%D0%B4%D0%B8%D0%B0%D0%B3%D0%BD%D0%BE%D1%81%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9%20%D1%86%D0%B5%D0%BD%D1%82%D1%80%2C%20%D0%9E%D0%A1%D0%9F%20%E2%84%96%201&utm_source=share&z=16.83" width="100%" height=500px" frameborder="0" allowfullscreen="true" style="position:relative;border:1px solid #e6e6e6;border-radius:8px;"></iframe></div>`;
/**
 * Идентификатор для корректной навигации в шапке.
 * @interface
 *
 * @property {string} id Идентификатор блока.
 */
interface mapBlockProps {
  id: string;
}

/**
 * Данные по департаменту.
 * @interface
 *
 * @property {string} title Название подразделения.
 * @property {string} adress Адрес подразделения.
 * @property {string} yamapLink Ссылка на Яндекс.Карты с меткой подразделения.
 */
interface departmentsData {
  title: string;
  adress: string;
  yamapLink: string;
}

/**
 * Часы работы подразделений.
 * @interface
 *
 * @property {string} workdays Рабочие дни.
 * @property {string} weekends Выходные.
 */
interface departmentHours {
  workdays: string;
  weekends: string;
}

/**
 * Компонент, отображающий блок карты с расположением основного подразделения.
 *
 * @param {mapBlockProps} id - Идентификатор для корректной навигации в шапке.
 * @return {React.FC}  Отрисованный компонент MapBlock.
 */
const MapBlock: React.FC<mapBlockProps> = ({ id }) => {
  const [data, setData] = useState<departmentsData[]>([]);
  const [data2, setData2] = useState<departmentHours[]>([]);

  useEffect(() => {
    // Заглушка с данными по подразделениям
    const departmentsData: departmentsData[] = [
      {
        title: "СКДЦ ВолгГМУ",
        adress: "ул. Герцена 10",
        yamapLink: "https://yandex.ru/maps/-/CDbP6BjY",
      },
      {
        title: "Структурное подразделение №1",
        adress: "ул. Кирова, 113",
        yamapLink: "https://yandex.ru/maps/-/CDbP6Jop"
      },
      {
        title: "Структурное подразделение №2",
        adress: "ул. Тулака, 2/1",
        yamapLink: "https://yandex.ru/maps/-/CDbP6J3S"
      },
    ];
    setData(departmentsData);
  }, []);

  useEffect(() => {
    // Заглушка с данными по часам работы
    const departmentHours: departmentHours[] = [
      {
        workdays: "Понедельник-пятница - с 8:00 до 20:00",
        weekends: "Суббота - с 8:00 до 14:00. Воскресенье - выходной",
      },
    ];
    setData2(departmentHours);
  }, []);

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only("xs"));

  const TitleText = {
    color: "#288e81",
    fontSize: isXsScreen ? 22 : 38,
    fontFamily: "Austin, sans-serif",
    textTransform: "uppercase",
    // maxWidth: isXsScreen ? 240 : 500,
    textAlign: isXsScreen ? "center" : "left",
    // marginBottom: "25px",
  };
  return (
    <Box sx={{ marginBottom: "50px" }} id={id}>
      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={TitleText}>Где мы находимся</Typography>
          <Button
            color="primary"
            sx={{
              color: "white",
              backgroundColor: "#288e81",
              borderRadius: "30px",
              fontSize: "14px",
              textTransform: "none",
              padding: "8px 36px",
              display: { xs: "none", sm: "none", lg: "flex" },
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: "#1a665d",
              },
            }}
            href="https://yandex.ru/maps/-/CDbP6BjY"
          >
            Посмотреть на карте
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <div dangerouslySetInnerHTML={{ __html: yandexMapWidget }}></div>
            <Button
              color="primary"
              sx={{
                color: "white",
                backgroundColor: "#288e81",
                borderRadius: "30px",
                fontSize: "14px",
                textTransform: "none",
                padding: "8px 36px",
                marginTop: "15px",
                display: { xs: "flex", sm: "none", lg: "none" },
                alignContent: "center",
                whiteSpace: "nowrap",
                "&:hover": {
                  backgroundColor: "#1a665d",
                },
                alignItems: "center",
              }}
              href="https://yandex.ru/maps/-/CDbP6BjY"
            >
              Посмотреть на карте
            </Button>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                marginTop: "15px",
              }}
            >
              {data.map((item, index) => (
                <div key={index}>
                  <OutlineButton
                    buttonText={`${item.title}`}
                    buttonLink={item.yamapLink}
                  />
                </div>
              ))}
            </Box>
            <Box sx={{ marginTop: "15px" }}>
              <Typography sx={TitleText}>График работы клиники</Typography>
              {data2.map((item, index) => (
                <div key={index}>
                  <Typography>{item.workdays}</Typography>
                  <Typography>{item.weekends}</Typography>
                </div>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MapBlock;
