import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

const links = [
  {
    title: "Правила предоставления платных медицинских услуг",
    link: "https://www.volgmed.ru/uploads/files/2022-11/161900-aeoomocsunulvmby35uvvoryyuuubsyr.pdf",
  },
  {
    title: "Положение о Стоматологической поликлинике",
    link: "https://www.volgmed.ru/uploads/files/2017-1/64685-polozhenie_o_stomatologicheskoj_poliklinike_2016.pdf",
  },
  {
    title: "Анкета Минздрава",
    link: "https://minzdrav.gov.ru/open/supervision/format/nezavisimaya-sistema-otsenki-kachestva-okazaniya-uslug-meditsinskimi-organizatsiyami/nezavisimaya-otsenka-kachestva-okazaniya-uslug-meditsinskimi-organizatsiyami",
  },
  {
    title: "Лицензия",
    link: "https://www.volgmed.ru/uploads/files/2023-7/176789-litsenziya_klinika_volggmu_l041_00110_34_00368034.pdf",
  },
  {
    title: "Сведения о сотрудниках СКДЦ",
    link: "https://www.volgmed.ru/uploads/files/2024-3/194605-spisok_vrachey_gertsena_032024.pdf",
  },
  {
    title: "Сведения о сотрудниках Обособленного структурного подразделения №1",
    link: "https://www.volgmed.ru/uploads/files/2024-4/195734-personalnyy_sostav_sotrudnikov_skdts_kirova_113_sayt.pdf",
  },
  {
    title: "Сведения о сотрудниках Обособленного структурного подразделения №2",
    link: "https://www.volgmed.ru/uploads/files/2024-3/194606-spisok_vrachey_tulaka_032024.pdf",
  },
  {
    title: "Внесение изменений в реестр лицензий",
    link: "https://www.volgmed.ru/uploads/files/2023-7/177857-vnesenie_izmeneniy_v_reestr_litsenziy_iyul_2023.pdf",
  },
  {
    title: "Информированное добровольное согласие",
    link: "https://www.volgmed.ru/uploads/files/2023-9/182365-ids_na_meditsinskoe_vmeshatelstvo.pdf",
  },
  {
    title: "Информированное согласие пациента на предоставление платных медицинских услуг",
    link: "https://www.volgmed.ru/uploads/files/2023-9/182366-soglasie_na_platnye_uslugi_iz_isdmu.pdf",
  },
  {
    title: "Календарь профилактических прививок",
    link: "https://www.volgmed.ru/uploads/files/2022-9/158343-prmz_1122_06122021.pdf",
  },
  {
    title: "Прейскурант цен на платные медицинские услуги",
    link: "https://www.volgmed.ru/uploads/files/2024-2/193818-obshchie_vidy_rabot_2024.pdf",
  },
  // {
  //   title: "Подразделение, ул. КИМ, 20",
  //   link: "https://www.volgmed.ru/uploads/files/2022-11/161729-kim_20_na_08noyabrya_22.pdf",
  // },
  {
    title: "Файловое хранилище",
    link: "https://www.volgmed.ru/medicine/dentistry-clinic/faylovyy-menedzher/",
  },
];

function DocsView() {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLgScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const styles = {
    titleBlock: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      maxWidth: "640px",
      padding: "0",
      marginBottom: isMdScreen ? "45px" : "90px",
      paddingTop: isSmScreen ? "45px" : "0px",
    },
    titleText: {
      marginBottom: isMdScreen ? "20px" : "40px",
      fontSize: isSmScreen ? "25px" : isLgScreen ? "33px" : "37px",
      lineHeight: "1.23",
      fontFamily: "Austin, Arial, sans-serif",
      fontWeight: "600",
      color: "rgb(8, 142, 129)",
    },
    titleSubText: {
      maxWidth: "560px",
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: isSmScreen ? "20px" : isLgScreen ? "22px" : "24px",
      lineHeight: isSmScreen ? "1.4" : "1.5",
      fontFamily: "PT Sans",
      fontWeight: "300",
      color: "black",
    },
    listCards: {
      marginBottom: 0,
      marginLeft: "auto",
      marginRight: "auto",
      padding: 0,
      maxWidth: isMdScreen ? "640px" : isLgScreen ? "960px" : "1200px",
    },
    cardItem: {
      cursor: "pointer",
      display: "inline",
      marginX: isLgScreen ? "10px" : "20px",
      maxWidth: isMdScreen ? "100%" : "240px",
      backgroundColor: "white",
      borderRadius: "5px",
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px 0px",
      height: isMdScreen ? "auto" : "auto",
      width: isMdScreen ? "auto" : "100%",
    },
    card: {
      padding: "30px 30px 40px",
    },
    cardTitle: {
      fontSize: isLgScreen ? "20px" : "22px",
      lineHeight: "1.35",
      fontFamily: "PT Sans",
      fontWeight: "600",
      color: "black",
    },
    cardButton: {
      width: "100%",
      marginTop: "10px",
      display: "inline-block",
      color: "#ff8562",
      fontFamily: "PT Sans",
      fontSize: "16px",
      lineHeight: "1.55",
      fontWeight: "700",
      textDecoration: "none",
    },
  };

  return (
    <div style={{ marginBottom: "60px" }}>
      <div style={styles.titleBlock}>
        <Typography textAlign={"center"} style={styles.titleText}>
          Документы и важная информация
        </Typography>

        <Typography textAlign={"center"} style={styles.titleSubText}>
          Для уточнения другой информации звоните в приемный покой <br /> 73-09-23
        </Typography>
      </div>

      <Stack
        direction={isMdScreen ? "column" : "row"}
        justifyContent={"center"}
        spacing={isMdScreen ? 2 : 2}
        useFlexGap
        flexWrap={"wrap"}
        style={styles.listCards}
        flexGrow={1}
      >
        {links.map((item) => (
          <div style={styles.cardItem}>
            <div style={styles.card}>
              <Typography style={styles.cardTitle}>{item.title}</Typography>
              <Typography style={styles.cardButton}>
                <a style={styles.cardButton} href={item.link}>
                  {"Перейти →"}
                </a>
              </Typography>
            </div>
          </div>
        ))}
      </Stack>
    </div>
  );
}

export default DocsView;
